var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"paymentCard",attrs:{"loading":_vm.loading,"disabled":_vm.disabled}},[_c('v-card-title',[_vm._v("Payment")]),(_vm.showPaymentField)?_c('div',[_c('v-card-text',[_vm._v(" Amount need to be paid: "+_vm._s(_vm.rate)+" LKR"),_c('br'),_vm._v(" Order ID: "+_vm._s(_vm.orderId)+" ("+_vm._s(_vm.shipmentNo)+") "),_c('p',[_vm._v("Your Previous Proof have been rejected, kindly reupload")]),_c('v-select',{attrs:{"items":_vm.paymentOptions,"item-value":"id","item-text":"name","return-object":"","label":"Payment Method","rules":[
          {
            required: true,
            message: 'Please select your prefered payment method',
          } ]},on:{"change":function($event){return _vm.onPayModeChange()}},model:{value:(_vm.newPreferedPayMode),callback:function ($$v) {_vm.newPreferedPayMode=$$v},expression:"newPreferedPayMode"}}),_c('ul',[(_vm.newPreferedPayMode.id == 'online')?_c('li',[_vm._v(" If you would like to make the payment online using your Debit or Credit cards, kindly send us a message to our WhatsApp on +94755192193 requesting for a payment link along with your order ID. ")]):_vm._e(),(
            _vm.newPreferedPayMode.id == 'bankTransfer' ||
            _vm.newPreferedPayMode.id == 'bankDeposit'
          )?_c('li',[_vm._v(" After making "+_vm._s(_vm.newPreferedPayMode.id == "bankTransfer" ? "Bank Transfer" : "Bank Deposit")+" Kindly upload the proof photo here ")]):_vm._e(),(_vm.newPreferedPayMode.id == 'cash')?_c('li',[_vm._v(" You can pay cash at our office ")]):_vm._e()]),(
          _vm.newPreferedPayMode.id == 'bankTransfer' ||
          _vm.newPreferedPayMode.id == 'bankDeposit'
        )?_c('v-file-input',{staticClass:"mt-2",attrs:{"label":_vm.newPreferedPayMode.id == 'bankTransfer'
            ? 'Bank Transfer' + ' Proof'
            : 'Bank Deposit' + ' Proof',"placeholder":"Select your file","prepend-icon":"mdi-paperclip","outlined":"","dense":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}):_vm._e()],1),_c('v-card-actions',[(
          _vm.newPreferedPayMode.id == 'bankTransfer' ||
          _vm.newPreferedPayMode.id == 'bankDeposit'
        )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submitProof()}}},[_vm._v(" Submit "+_vm._s(_vm.newPreferedPayMode.id == "bankTransfer" ? "Bank Transfer" + " Proof" : "Bank Deposit" + " Proof")+" ")]):_vm._e()],1)],1):_c('div',[_c('v-card-text',[_c('p',[_vm._v(" Your payment proof has been submitted, kindly wait until it is processed ")]),_c('p',[_vm._v(" For further information "),_c('br'),_vm._v(" contact: "),_c('b',[_vm._v("info@colombomail.lk")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }