<template>
  <v-card class="paymentCard" :loading="loading" :disabled="disabled">
    <v-card-title>Payment</v-card-title>

    <div v-if="showPaymentField">
      <v-card-text>
        Amount need to be paid: {{ rate }} LKR<br />
        Order ID: {{ orderId }}

        ({{ shipmentNo }})

        <p>Your Previous Proof have been rejected, kindly reupload</p>
        <v-select
          :items="paymentOptions"
          item-value="id"
          item-text="name"
          return-object
          v-model="newPreferedPayMode"
          @change="onPayModeChange()"
          label="Payment Method"
          :rules="[
            {
              required: true,
              message: 'Please select your prefered payment method',
            },
          ]"
        >
        </v-select>

        <ul>
          <li v-if="newPreferedPayMode.id == 'online'">
            If you would like to make the payment online using your Debit or Credit cards, kindly send us a message to our WhatsApp on +94755192193 requesting for a payment link along with your order ID.
          </li>
          <!-- <li v-if="newPreferedPayMode.id == 'online'">
            To make payment online click the button below
          </li> -->
          <!-- <li v-if="newPreferedPayMode.id == 'online'">
            There will be 3% additional charge for online payment
          </li>
          <li v-if="newPreferedPayMode.id == 'online'">
            You need to pay <b>{{ onlineRate }} LKR</b>, if online
          </li> -->
          <li
            v-if="
              newPreferedPayMode.id == 'bankTransfer' ||
              newPreferedPayMode.id == 'bankDeposit'
            "
          >
            After making
            {{
              newPreferedPayMode.id == "bankTransfer"
                ? "Bank Transfer"
                : "Bank Deposit"
            }}
            Kindly upload the proof photo here
          </li>
          <li v-if="newPreferedPayMode.id == 'cash'">
            You can pay cash at our office
          </li>
        </ul>

        <v-file-input
          v-if="
            newPreferedPayMode.id == 'bankTransfer' ||
            newPreferedPayMode.id == 'bankDeposit'
          "
          :label="
            newPreferedPayMode.id == 'bankTransfer'
              ? 'Bank Transfer' + ' Proof'
              : 'Bank Deposit' + ' Proof'
          "
          class="mt-2"
          placeholder="Select your file"
          prepend-icon="mdi-paperclip"
          outlined
          dense
          v-model="file"
        >
        </v-file-input>
      </v-card-text>

      <v-card-actions>
        <!-- <v-btn
          v-if="newPreferedPayMode.id == 'online'"
          color="primary"
          @click="pay()"
          >Pay Now
        </v-btn> -->
        <v-btn
          v-if="
            newPreferedPayMode.id == 'bankTransfer' ||
            newPreferedPayMode.id == 'bankDeposit'
          "
          color="primary"
          @click="submitProof()"
        >
          Submit
          {{
            newPreferedPayMode.id == "bankTransfer"
              ? "Bank Transfer" + " Proof"
              : "Bank Deposit" + " Proof"
          }}
        </v-btn>
      </v-card-actions>
    </div>

    <div v-else>
      <v-card-text>
        <p>
          Your payment proof has been submitted, kindly wait until it is
          processed
        </p>
        <p>
          For further information <br />
          contact: <b>info@colombomail.lk</b>
        </p>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { paymentService } from "../../services";
export default {
  name: "paymentCard",
  props: [
    "orderId",
    "docId",
    "rate",
    "fName",
    "lName",
    "email",
    "phone",
    "shipmentId",
    "shipmentNo",
    "paymentProofSubmitted",
    "paymentProofs",
  ],
  created() {
    this.init();
  },
  data() {
    return {
      //cost
      onlineRate: 0,

      //card
      disabled: false,
      loading: false,
      showPaymentField: true,
      proofRejected: false,

      //payment
      newPreferedPayMode: { id: null, name: null },
      paymentOptions: [
        {
          id: "online",
          name: "Online Payment",
        },
        {
          id: "bankTransfer",
          name: "Bank Transfer",
        },
        {
          id: "bankDeposit",
          name: "Bank Deposit",
        },
        {
          id: "cash",
          name: "Cash",
        },
      ],

      //proof
      file: null,
    };
  },

  methods: {
    init() {
      console.log(
        "kk",
        this.paymentProofs[this.paymentProofs.length - 1].status
      );
      if (this.paymentProofs) {
        if (
          this.paymentProofs[this.paymentProofs.length - 1].status == "rejected"
        )
          this.showPaymentField = true;
        else if (
          this.paymentProofs[this.paymentProofs.length - 1].status ==
          "submitted"
        )
          this.showPaymentField = false;
        else if (
          this.paymentProofs[this.paymentProofs.length - 1].status == "accepted"
        )
          this.showPaymentField = false;

        this.proofRejected =
          this.paymentProofs[this.paymentProofs.length - 1].status == "rejected"
            ? true
            : false;
      } else {
        this.showPaymentField = true;
      }
    },
    getDate(date) {
      console.log(date);
      // return moment(date).format("DD MMM YYYY");
    },
    getTime(date) {
      console.log(date);
      // return moment(date).format("hh:mm A");
    },

    onPayModeChange() {
      if (this.newPreferedPayMode.id == "online") {
        const onlineCommissionCost = this.rate * 0.03;
        this.onlineRate = Math.ceil(this.rate + onlineCommissionCost);
      }
    },
    async pay() {
      console.log("pay method in view running.....");

      const finalRate =
        this.newPreferedPayMode.id == "online" ? this.onlineRate : this.rate;
      await paymentService.payNow(
        finalRate,
        this.docId,
        this.fName,
        this.lName,
        this.email,
        this.phone
      );
      //   console.log(data);
    },
    async submitProof() {
      console.log("submitProof method in view running.....");
      if (this.file == null || this.file == undefined) {
        alert("Please select a file");
      } else {
        this.loading = true;
        this.disabled = true;

        const proofNo = this.paymentProofs ? this.paymentProofs.length + 1 : 1;
        await paymentService.submitProof(
          this.file,
          this.shipmentId,
          this.orderId,
          this.shipmentNo,
          proofNo
        );
        this.$router.go();
        this.loading = false;
        this.disabled = false;
      }
    },
  },
};
</script>

<style scoped>
.paymentCard {
  background-color: #fff;
  padding: 0px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
  margin-bottom: 20px;
}
ul {
  width: 300px;
}
</style>